
import CommonMixin from '@/mixins/CommonMixin';
import PaginationMixin from '@/mixins/PaginationMixin';
import ToolsMixin from '@/mixins/ToolsMixin';
import { handleErrorUI } from '@/util/error';
import ManagementMixin from '@/mixins/ManagementMixin';
import Payment from '@/models/Payment';

export default {
  mixins: [ManagementMixin, CommonMixin, ToolsMixin, PaginationMixin],
  data() {
    return {
      activeDialog: '',
      dialogShown: false,
      selectedPayment: null,
      paymentMethods: [],
      loading: false,
      addPaymentMethodDialogShown: false
    };
  },
  async created() {
    await this.fetchPaymentMethods();
  },
  methods: {
    async fetchPaymentMethods() {
      this.loading = true;
      try {
        const { tenant } = await this.$repo.session.fetch();
        const { data, pagination } = await this.$repo.payment.getPaymentCards(
          tenant,
          this.pagination
        );
        this.paymentMethods = data;
        this.updatePagination(pagination);
      } catch (e) {
        handleErrorUI(e);
      } finally {
        this.loading = false;
      }
    },
    async paginationChangeHandler() {
      await this.fetchPaymentMethods();
    },
    openDialog(componentName: string) {
      this.activeDialog = componentName;
      this.dialogShown = true;
    },
    addPaymentMethod() {
      this.addPaymentMethodDialogShown = true;
    },
    async closeAddPaymentMethodDialog() {
      this.addPaymentMethodDialogShown = false;
      await this.fetchPaymentMethods();
    },
    removeCard(payment: Payment) {
      this.selectedPayment = payment;
      this.openDialog('RemovePaymentCard');
    },
    async onDelete() {
      this.dialogShown = false;

      await this.$repo.payment.removePayment(this.selectedPayment.id);
      await this.fetchPaymentMethods();
    }
  }
};
